export default {
  siteTitle: 'Get bonus',
  blogerName: 'SHELAO',
  socialsList: [
    {
      name: 'twitch',
      url: 'https://www.twitch.tv/shelao'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/crissalva40/?hl=es-la'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/shelao'
    }
  ],
  projects: [
    {
      name: 'sol',
      url: 'https://solcasino.life/c5a7018a2',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cc6a84556',
      gameTitle: 'Jet Air (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c67c09bb9',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c3437e29b',
      gameTitle: 'Izzi Art (BGaming)',
    },

  ],
  headerText: 'Regístrate con mi código promocional <strong>SHELAO</strong> y obtén 50 giros gratis en los siguientes juegos',
  yourPromocode: 'Tu código promocional',
  gameBonus: 'Bono en el juego',
  promocode: 'SHELAO',
  casinoBonus1: '<strong>100% + 500FS</strong><div>en el primer depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>para registro con código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado en el portapapeles',
  theme: 'dark'
};
